<template>
  <base-section id="different">
    <base-section-heading
      color="grey lighten-2"
      title="Perbedaan Takaful dengan Konvensional"
      class="mt-5"
    />

    <v-container>
      <template>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center body-1 font-weight-bold primary white--text">
                Takaful
              </th>
              <th class="text-center body-1 font-weight-bold fourth white--text">
                Konvensional
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in different"
              :key="item.id"
            >
              <td>{{ item.syariah }}</td>
              <td>{{ item.conventional }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFAQ',

    data: () => ({
      different: [
        {
          syariah: 'Sumber hukum berasal dari Al-Qur\'an, Al-Hadits, UU, dan peraturan yang berlaku',
          conventional: 'Sumber hukum hanya berasal dari UU dan peraturan yang berlaku',
        },
        {
          syariah: 'Akad utama berdasarkan akad tabarru\' yaitu akad tolong-menolong antar sesama peserta',
          conventional: 'Akad utama berdasarkan prinsip jual beli yang bersifat komersial',
        },
        {
          syariah: 'Kontribusi yang diterima dipisahkan menjadi 2 (dua) bagian : (1) dana tabarru\' peserta; dan (2) dana pengelola',
          conventional: 'Premi yang diterima tidak dipisahkan namun dimasukkan ke dalam 1 (satu) akun yaitu dana perusahaan asuransi',
        },
        {
          syariah: 'Sumber pendapatan pengelola berasal dari fee/ujroh, hasil investasi, pembagian surplus underwriting, dan biaya administrasi lainnya',
          conventional: 'Sumber pendapatan perusahaan berasal dari premi yang diterima, hasil investasi, dan keuntungan dari surplus underwriting beserta biaya administrasi lainnya',
        },
        {
          syariah: 'Objek yang diasuransikan adalah objek halal yang tidak bertentangan dengan syariah',
          conventional: 'Objek yang diasuransikan tidak melihat halal dan haram',
        },
        {
          syariah: 'Pengelolaan risiko berdasarkan prinsip \'sharing of risk\' diantara peserta',
          conventional: 'Pengelolaan risiko berdasarkan prinsip \'transfer of risk\' dari pemegang polis ke perusahaan asuransi',
        },
        {
          syariah: 'Investasi dana tabarru\' peserta maupun dana pengelola dilakukan pada instrumen investasi yang sesuai undang-undang dan tidak bertentangan dengan prinsip syariah',
          conventional: 'Investasi dana premi perusahaan dilakukan pada instrumen investasi sesuai undang-undang tanpa mempertimbangkan prinsip syariah',
        },
        {
          syariah: 'Sumber dana pembayaran klaim berasal dari kumpulan dana tabarru\' peserta',
          conventional: 'Sumber dana pembayaran klaim berasal dari dana perusahaan',
        },
        {
          syariah: 'Terdapat opsi pembagian surplus underwriting tabarru\' kepada peserta',
          conventional: 'Tidak ada opsi pembagian surplus underwriting kepada tertanggung.',
        },
        {
          syariah: 'Memiliki DPS (Dewan Pengawas Syariah) yang bertugas mengawasi pelaksanaan operasional perusahaan agar terbebas dari praktek-praktek muamalah yang bertentangan dengan prinsip syariah',
          conventional: 'Tidak memiliki DPS sehingga dalam prakteknya tidak dilakukan pengawasan terhadap pelaksanaan operasional perusahaan apakah menyimpang dari prinsip syariah atau tidak',
        }
      ],
    }),
  }
</script>
